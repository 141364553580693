document.addEventListener('DOMContentLoaded', function () {
  const items = document.querySelectorAll(
    '.animated_item, .animated_item_left'
  );
  const observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry, index) => {
        if (entry.isIntersecting) {
          const delay = `${index * 0.5}s`;
          entry.target.style.setProperty('--delay', delay);
          entry.target.classList.add('visible');
          observer.unobserve(entry.target);
        }
      });
    },
    { threshold: 0.3 }
  );

  items.forEach(item => {
    observer.observe(item);
  });
});
